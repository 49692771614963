.people-info-cell-main-container {
  display: flex;
  padding: 0.5em;

  p {
    line-height: 3em;
    margin: 0 1em;
  }
}

.people-icon-main-container {
  width: 3em;
  height: 3em;
  border-radius: 3em;
  text-align: center;

  .people-icon-initials {
    color: #{$card};
    line-height: 3em;
    vertical-align: middle;
    font-weight: 600;
  }

  img {
    width: 3em;
    height: 3em;
    border-radius: 3em;
  }
}
