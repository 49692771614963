.drop-zone {
  min-height: 250px;
  border: 2px dashed #{$primary};
  cursor: pointer;
  align-items: center;
  margin-bottom: 25px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  height: 50%;
}
.drop-zone.valid-drag {
  border: 2px dashed #{$success};
}
.drop-zone.invalid-drag {
  border: 2px dashed #{$danger};
}

.clear-files-btn {
  align-self: end;
}

.remove-file-btn-container {
  vertical-align: middle;
  display: flex;
  > .remove-file-btn {
    color: red;
    background-color: transparent;
    border: none;
    font-size: 30px;
  }
}

.drop-zone-file-container {
  display: flex;
  justify-content: space-between;
  background-color: adjust-color($color: $primary, $alpha: -0.95);
  margin-bottom: 8px;
  padding: 10px;
}
