.common-form {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;

  input {
    height: 2rem;
    font-size: 0.9rem;
    vertical-align: middle;
    background-color: #f5f5f5;
    box-shadow: none;
    border: 1px solid #d9d9d9;
    border-radius: 0.31rem;
    transition: all 0.3s;
    margin-bottom: 20px;
    padding-left: 10px;
  }
  input:focus {
    outline: none;
    border: 1px solid #{$primary};
  }
  .form-value-input {
    min-width: 100%;
  }

  .dropdown-toggle {
    width: 100%;
    min-height: 2rem;
    font-size: 0.9rem;
    vertical-align: middle;
    background-color: #f5f5f5;
    color: black;
    border: 1px solid #d9d9d9;
    border-radius: 0.31rem;
    transition: all 0.3s;
    margin-bottom: 30px;
    padding-left: 10px;
    text-align: start;
  }
  .dropdown-toggle:focus {
    outline: none;
    border: 1px solid #{$primary};
    background-color: #f5f5f5;
  }
  .dropdown-toggle:hover {
    outline: none;
    border: 1px solid #{$primary};
    background-color: #f5f5f5;
  }
  .dropdown-toggle.btn.btn-primary::after {
    visibility: hidden;
  }

  .form-label-main-container {
    display: flex;
    flex-direction: column;
    margin-right: 5%;

    .form-label-and-checkbox {
      display: flex;
      vertical-align: middle;
      font-size: 0.8rem;
      justify-content: space-between;

      .form-check {
        padding: 0;
        margin: 0 0 0 20px;
        vertical-align: middle;
      }

      .form-check-label {
        margin: 0;
      }

      .form-check-input {
        margin: 4px 5px 0 0;
        height: 15px;
        width: 15px;
        vertical-align: middle;
      }
    }
  }

  .form-check-input {
    height: 15px;
    width: 15px;
    vertical-align: middle;
  }

  .form-submit-button {
    width: 6rem;
    height: 2.2rem;
    background-color: #{$card};
    color: #{$primary};
    border-radius: 0.31em;
    border: 1px solid #{$primary};
  }
  .form-submit-button:hover {
    background-color: #{$primary};
    color: #{$card};
    transition: all 0.3s;
  }
  .form-submit-button.disabled {
    cursor: not-allowed;
  }
  .form-submit-button.disabled:hover {
    background-color: #{$card};
    color: #{$primary};
  }
  .form-input-text-addon {
    height: 2.2rem;
  }
}
