.dev-tools-download-button-container {
  display: flex;
  justify-content: space-around;
}

.dev-tools-upload-container {
  display: flex;
  column-gap: 8px;
  margin-top: 20px;
}
