.card-container {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;

  // MEMO: Per il momento punto all'immagine di frontend di dev, poi vedere
  background-image: url('https://dev-app.notifyme.tech/assets/auth-wallpaper.jpg');
  background-position: center;
  background-size: cover;
}

.card-header {
  background-color: #ffff;
  border-bottom: var(--bs-card-border-width) solid #d9d9d978;
}

// .card-body {
//   border-bottom: var(--bs-card-border-width) solid #d9d9d978;
// }

.btn-primary {
  --bs-btn-color: #{$btn-color};
}

.btn-lg,
.btn-group-lg > .btn {
  --bs-btn-border-radius: 0.2rem;
  font-size: 1.5rem;
}

a {
  text-decoration: none;
}

.navbar-light {
  background-color: #ffff;
}

.navbar {
  height: 3.5rem;

  padding-left: 0.725rem;
  padding-right: 0.725rem;

  .navbar-brand {
    svg {
      width: auto;
      height: 2.2rem;
    }
  }
}
