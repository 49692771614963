$primary: #0097a7;
$secondary: #424242;
$success: #a7c500;
$danger: #d32f2f;
$warning: #ff8e05;
$background: #f0f2f5;
$card: white;

$btn-color: #eceded;
$body-bg: #eceded;

$font-family: Lato, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';

/*
  // https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
@primary-color: #0097a7;
@primary-light: #56c8d8;
@primary-dark: #006978;
@secondary-color: #424242;
@secondary-light: #6d6d6d;
@secondary-dark: #1b1b1b;
@success-color: #a7c500;
@success-light: #dcf84c;
@success-dark: #739400;
@danger-color: #d32f2f;
@danger-light: #ff6659;
@danger-dark: #9a0007;
@warning-color: #ff8e05;
@warning-light: #ffbf47;
@warning-dark: #c55f00;
@primary-1: #00000014;
@border-radius-base: 0.31rem;
@info-color: @primary-color;
@layout-header-background: @secondary-color;
@layout-sider-background: #3e4c58;
// @border-color: #c1c1c1;
@border-color-focused: @primary-color;
@font-family: Lato, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
@menu-dark-item-active-bg: @primary-1;
@menu-dark-item-hover-bg: @primary-1;
@badge-height: 0.9rem;
@badge-font-size: 0.6rem;
@badge-font-weight: bold;
@text-colorxx: #ff0000;
@text-color: #3e4c58;
@menu-text-color: white;
@menu-title-color: #8a9ba8;
@menu-active-color: #fafafa;
@menu-account-br-color: #ebebeb;
@menu-selected-color: #323234;
@menu-category-color: #9e9e9e;
@right-panel-unselected-tab: #bdbdbd;
@label-color: #c5e4e8;
@backgorund-color: #eceded;
@account-bg-content-color: white;
@account-bg-color: #f0f2f5;
@border-color: @primary-light;
@topbar-order: #c5e4e8;


@border-input-antd: #d9d9d9;
@bg-input-antd-disable: #f5f5f5;

  */
