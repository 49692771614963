.card {
  display: flex;
  flex-direction: column;
}

.card-title {
  text-align: center;
  font-weight: 600;
  // margin-bottom: 1rem;
}
