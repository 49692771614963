.message-box-buttons-main-container {
  width: 100%;
  min-height: 70px;
  padding-top: 32px;
  display: flex;
  flex-direction: row;

  .message-box-buttons-child-container {
    width: 50%;
    min-height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .message-box-action-button {
      min-width: 80px;
      height: 100%;
      border-radius: 0.5rem;
      font-size: 0.8rem;
      text-transform: uppercase;
      transition: all 0.3s;
    }
    .yes {
      margin-right: 16px;
      color: #{$primary};
      background-color: #{$card};
      border: 1px solid #{$primary};
    }
    .yes:hover {
      color: #{$card};
      background-color: #{$primary};
      border: none;
    }
    .no {
      margin-right: 16px;
      color: #{$danger};
      background-color: #{$card};
      border: 1px solid #{$danger};
    }
    .no:hover {
      color: #{$card};
      background-color: #{$danger};
      border: none;
    }
    .cancel {
      color: #{$secondary};
      background-color: #{$background};
      border: none;
    }
    .cancel:hover {
      border: 1px solid #{$secondary};
    }
  }
  .cancel {
    justify-content: flex-end;
  }
}
