.company-info-cell-main-container {
  display: flex;
  padding: 0.5em;

  p {
    line-height: 3em;
    margin: 0 1em;
  }
}

.company-icon-main-container {
  width: 3em;
  height: 3em;
  border-radius: 3em;
  text-align: center;

  .company-icon-initials {
    color: #{$card};
    line-height: 3em;
    vertical-align: middle;
    font-weight: 600;
  }

  img {
    width: 3em;
    height: 3em;
    border-radius: 3em;
  }
}

.company-detail-input {
  border: 1px solid #a9a9a9;
  border-radius: 0.4rem;
  margin-bottom: '2rem';
  min-width: 350px;
  width: 30%;
}
.company-detail-input:focus {
  border: 1px solid #{$primary};
  outline: none;
}

.company-detail-info-subtitle {
  font-size: 0.8rem;
}

.api-key-container {
  max-width: 110px;
  margin: 0;
}
