.btn-primary {
  --bs-btn-color: #{$btn-color};
}

a {
  text-decoration: none;
}

.navbar-light {
  background-color: #ffff;
}

.navbar {
  height: 3.5rem;

  padding-left: 0.725rem;
  padding-right: 0.725rem;

  .navbar-brand {
    svg {
      width: auto;
      height: 2.2rem;
    }
  }
}
