.icon-button {
  background-color: #{$card};
  color: #{$primary};
  border: none;
}

.icon-button-tooltiptext {
  font-size: 0.8rem;
  font-weight: 600;
  visibility: hidden;
  border-radius: 0.3rem;
  padding: 4px;
  background-color: #{$secondary};
  color: #{$card};
  text-align: center;
  position: absolute;
  z-index: 3;
}

.icon-button:hover .icon-button-tooltiptext {
  visibility: visible;
}
