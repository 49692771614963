form {
  label {
    margin-right: 2rem;
  }
}

.account-detail-info-subtitle {
  font-size: 0.8rem;
}

.account-detail-info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.account-detail-info-subcontainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
