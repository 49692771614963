.hangfire-state-card {
  margin-top: 1.5rem;
  &.hf-base {
    background-color: var(--hangfire-base-bg-color);
  }
  &.hf-success {
    background-color: var(--hangfire-success-bg-color);
  }
  &.hf-warning {
    background-color: var(--hangfire-warning-bg-color);
  }
  &.hf-danger {
    background-color: var(--hangfire-danger-bg-color);
  }
  &.hf-delete {
    background-color: var(--hangfire-delete-bg-color);
  }
  > .card {
    padding: 0;
    margin: 0rem 1rem 1rem 1rem;
    border: none;
    background-color: #424242 !important;
    > .card-header {
      background-color: inherit;
      border-radius: unset;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      > .hangfire-state-text {
        font-family: monospace;
      }
      > .form-check.form-switch {
        > .form-check-input {
          cursor: pointer;
        }
      }
    }
    > .card-body {
      padding: 0;
    }
  }
}

.hangfire-logs {
  padding: 12px;
}
.hangfire-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}
.hangfire-state-text {
  display: contents;
  color: white;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.875rem;
}
.hangfire-state {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem 0.75rem 1rem;
  > div.hangfire-logs-btn {
    color: white;
    > button {
      color: inherit !important;
    }
  }
}
.hangfire-container {
  width: 100%;
  height: 600px;
  background-color: #424242;
  color: white;
  white-space: pre-wrap;
  overflow: hidden;
}
.hangfire-scroll-container {
  overflow-y: auto;
  height: inherit;
}
.hangfire-content {
  table > tbody > tr {
    background-color: transparent;
    > td {
      border: none;
      height: 1.2em;
      font-family: monospace;
      padding: 0em 1.5em;
    }
    > td:first-child {
      width: 15%;
      text-align: right;
    }
  }
}
.hangfire-iframe-container {
  width: 100%;
  height: 700px;
  margin-top: 1.125rem;
}

:root {
  --hangfire-success-bg-color: rgba(167, 197, 0, 0.6);
  --hangfire-warning-bg-color: rgba(255, 142, 5, 0.6);
  --hangfire-danger-bg-color: rgba(211, 47, 47, 0.6);
  --hangfire-base-bg-color: rgba(0, 151, 167, 0.6);
  --hangfire-delete-bg-color: rgba(119, 119, 141, 0.6);
}
