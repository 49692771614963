.sider-menu {
  max-width: 240px;
  min-width: 240px;
  min-height: 100vh;
  width: 240px;
  background-color: #{$secondary}; // var(--bs-secondary-color);
  color: #{$body-bg};
  font-weight: 400;

  ul {
    list-style-type: none;
    padding: 0;
  }

  .main-menu {
    display: flex;
    height: 100%;
    flex-direction: column;

    .menu-content {
      flex: 1;
    }
    .menu-footer {
      padding: 8px 16px;

      button {
        width: 100%;
      }
    }
  }

  .menu-elem-group-title {
    transform: scale(1.1, 0.9) translateX(0.5rem);
    text-transform: uppercase;
    font-size: 12px;
    pointer-events: none;
    padding: 8px 16px;
    line-height: 1.5715rem;
    opacity: 0.65;
  }

  .menu-elem {
    margin-bottom: 8px;
    height: 3rem;
    display: flex;
    align-items: center;
    padding: 0 16px 0 0;
    margin-top: 4px;
    overflow: hidden;
    line-height: 40px;
    text-overflow: ellipsis;

    a {
      max-width: 240px;
      min-width: 240px;
      width: 240px;
      height: 100%;
      padding: 0 0 0 28px;
    }

    .menu-elem-icon {
      font-size: 1.2rem;
      color: #{$body-bg};
      /* width: 2.5rem;
      height: 2.5rem; */
      display: inline-block;
    }

    .menu-elem-label {
      font-size: 14px;
      padding-left: 1.7rem;
      color: #{$body-bg};
      text-decoration: none;
      display: inline-block;
    }
  }
  .menu-elem.selected {
    background-color: darken($color: #424242, $amount: 2);
    border-left: 3px solid #{$card};
  }
  .menu-elem:hover {
    background-color: darken($color: #424242, $amount: 2);
  }
}
