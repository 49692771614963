.action-button {
  min-height: 30px;
  max-height: 30px;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  color: #{$card};
  border: none;
  background: #{$primary};
}

.action-button.light {
  background-color: transparent;
  color: #{$primary};
  font-weight: 600;
}
