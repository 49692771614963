.main-layout-container {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #f0f2f5;
}

.card {
  padding: 2rem;
  margin: 2rem 2rem;
  background-color: #{$card};
  border: 1px solid #f0f0f0;
  border-radius: 0.31rem;
}

.modal-content {
  min-width: 450px;
}
