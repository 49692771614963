table {
  width: 100%;
  font-size: 14px;

  tr {
    background-color: #{$card};
  }
  tr.clickable {
    cursor: pointer;
  }

  tr:hover {
    background-color: darken($color: white, $amount: 2);

    .table-button {
      background-color: darken($color: white, $amount: 2);
    }
  }

  th {
    height: 3.5em;
    padding: 0.5em 1em;
    font-weight: 600;
    background-color: #{$background};
  }

  tbody {
    padding: 0;
    margin: 0;
  }

  td {
    border-bottom: 1px solid #{$background};
    height: 3.5em;
    padding: 0.5em 1em;
  }

  .bi {
    font-size: 1.5rem;
  }

  .table-button {
    background-color: #{$card};
    color: #{$primary};
    border: none;
  }
  .table-button:hover {
    background-color: #{$background};
  }

  .tooltiptext {
    font-size: 0.8rem;
    font-weight: 600;
    visibility: hidden;
    border-radius: 0.3rem;
    padding: 4px;
    background-color: #{$secondary};
    color: #{$card};
    text-align: center;
    position: absolute;
    z-index: 3;
  }

  .table-button:hover .tooltiptext {
    visibility: visible;
  }
}

.card {
  th {
    background-color: transparent;
  }
}

.table-footer-main-container {
  display: flex;
  width: 100%;
  font-size: 14px;
  justify-content: space-between;
  color: #8a9ba8;
  margin-top: 1em;
  padding: 0 1em 0;

  .paginating-controls-rpp-container {
    display: flex;
    align-items: baseline;

    p {
      width: 6rem;
    }

    button {
      width: 4.3em;
      height: 2.3em;
      margin-left: 1em;
      border: 1px solid #d9d9d9;
      background-color: #{$card};
      border-radius: 0.31em;
      color: #8a9ba8;
    }
    button:hover {
      border: 1px solid #{$primary};
    }
  }

  .paginating-controls-main-container {
    display: flex;
    justify-content: flex-end;

    .paginating-controls-ellipsis {
      width: 2em;
      height: 2em;
      background-color: transparent;
    }

    .paginating-controls-prev-next-button {
      width: 2em;
      height: 2em;
      border: 0 solid #{$background};
      background-color: transparent;
      color: #8a9ba8;
    }

    .paginating-controls-select-button {
      width: 2em;
      height: 2em;
      background-color: transparent;
      color: #8a9ba8;
      border: none;
    }
    .paginating-controls-select-button:hover {
      color: #{$primary};
    }
    .paginating-controls-select-button.active {
      color: #{$primary};
      border-radius: 0.31em;
      border: 1px solid #{$primary};
      background-color: #{$card};
    }
  }
}

.table-search-bar {
  border: 1px solid #a9a9a9;
  border-radius: 0.4rem;
  margin: 2rem 0 0 2rem;
  min-width: 30%;
}
.table-search-bar:focus {
  border: 1px solid #{$primary};
  outline: none;
}
