/*
  -01 TYPOGRAFY SYSTEM

    Spacing System (px)
    2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

    Font Size System (px)
    10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

  -02 COLORS

    #d9d9d9
    #ced4da
    #adb5bd
   
*/

.card.card-login {
  max-width: 750px;
  min-height: 715px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 6rem;
  align-items: center;
  margin: auto;
  background-color: #fff;

  button.btn {
    width: 10.9rem;
  }
}

.card-header-title {
  font-size: 18px;
  padding: 0 24px;
}

.card-header-subtitle {
  // border-top: solid #ced4da 5px;
  font-weight: 400;
  font-size: 1.4rem;
  text-align: center;
  border: none;
  // margin-bottom: 9.9rem;
}

.card-form {
  text-align: center;
  line-height: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.card-label {
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  letter-spacing: 0.5px;
}

.card-label::before {
  display: inline-block;
  margin-right: 4px;
  margin-left: -10px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.card-email-input,
.card-email-input-ko,
.card-pswd-input,
.card-pswd-input-ko {
  width: 100%;
  padding-left: 1rem;
  border: 1px solid #d9d9d9;
  border-radius: 0.31rem;
  outline: none;
  font-size: 24px;
  transition: all 0.5s;
}

.card-email-input-ko {
  border: solid 1px #ff7875;
}

.card-pswd-input,
.card-pswd-input-ko {
  display: inline-flex;
}

.card-pswd-input:active,
.card-pswd-input:focus,
.card-email-input:focus {
  border: solid 1px #1da9b3;
  box-shadow: 0 0 0 2px rgb(0 151 167 / 20%);
  border-right-width: 1px !important;
  outline: 0;
}

.card-email-input-ko:focus {
  border: solid 1px #ff7875;
  box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
  border-right-width: 1px !important;
  outline: 0;
}

.card-pswd-input-focus {
  border-right-width: 1px !important;
  outline: 0;
}

.ok {
  border: solid 1px #1da9b3;
  box-shadow: 0 0 0 2px rgb(0 151 167 / 20%);
}

.error {
  border: solid 1px #ff7875;
  box-shadow: 0 0 0 2px rgb(255 77 79 / 20%);
}

.card-email-input:hover,
.card-pswd-input:hover {
  border: solid 1px #1da9b3;
}

::-webkit-input-placeholder {
  color: #adb5bd;
}

// RIMUOVE OCCHIO DA INPUT
input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}

.input-affix {
  width: 100%;
  border: none;
  outline: none;
  padding: 0.5rem 0.5rem 0.5rem 0rem;
}

.input-suffix {
  display: flex;
  flex: none;
  align-items: center;
  font-size: 30px;
}

.card-span {
  margin-left: 5px;
}

.anticon {
  cursor: pointer;
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon-eye-invisible {
  margin-right: 1.5rem;
}
.ant-input-password-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}
